 <!--
 * @Author: lbh
 * @Date: 2021-04-21 11:08:48
 * @LastEditors: lbh
 * @LastEditTime: 2021-12-09 14:28:47
 * @Description: ricepon - 主頁
-->
<template>
  <div
    class="home t-body"
    :style="`min-height:${window.bodyH}px;`"
  >
    <selfHeader :type="window.type" />
    <div
      class="m-body"
      :class="window.type=='1000' ? '' : 'm-body_1'"
    >
      <!-- 輪播 -->
      <el-carousel
        indicator-position="outside"
        class="swiper"
        :style="`height:${window.swiperH}px`"
      >
        <el-carousel-item
          v-for="(item,index) in bannerImg"
          :key="index"
        >
          <el-image
            fit="cover"
            :src="window.type == '1000' ? item.bimg : item.img "
          >
            <div
              slot="error"
              class="image-slot"
            >
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </el-carousel-item>
      </el-carousel>
      <!-- App介紹 -->
      <div
        class="introduce-app"
        :class="window.introduce_class"
      >
        <div
          class="item theme_border_color"
          v-for="(item,index) in introduce"
          :key="index"
        >
          <h3><strong v-text="item.title"></strong></h3>
          <h6 v-text="item.content"></h6>
          <img :src="item.img" />
        </div>
      </div>
      <!-- 餐廳案例 -->
      <restaurantCase
        :widowW="window.w"
        :restaurantCase="restaurantCase"
      />
      <!-- 產品 -->
      <productsBox :type="window.type" />
    </div>
    <selfFooter :type="window.type" />
  </div>
</template>

<script>
import selfHeader from "@/components/header/header";
import selfFooter from "@/components/footer/footer";
import productsBox from "@/components/commo/products";
import restaurantCase from "@/components/commo/restaurantCase";
import util from "@/js/utils/util";
export default {
  name: 'Home',
  components: {
    selfHeader,
    selfFooter,
    productsBox,
    restaurantCase
  },
  data () {
    return {
      window: {
        type: '1000',
        bodyH: 0,
        swiperH: 0,
        introduce_class: '',
        restaurant_class: '',
        w: 0
      },
      bannerImg: [], // banner 圖
      introduce: [], // app 介紹
      restaurantCase: [], // 餐廳 案例

    }
  },
  mounted () {
    util.setTitle('Ricepon飯糰');
    // 初始化窗口
    this.initWindow();
    // 監聽 窗口
    window.onresize = () => {
      // 1000 700
      this.initWindow();
    };
    // 組裝banner 圖
    let bannerImg = [];
    bannerImg.push({
      img: util.getImgPath('banner1-0x0.png'),
      bimg: util.getImgPath('banner1-0x0.png')
    });
    this.bannerImg = bannerImg;
    // 組裝 app介紹
    let introduce = [
      { title: 'RICEPON飯糰', content: '多種點餐方式，讓您生活更方便省時', img: util.getImgPath('Web_mobile01-1.jpg') },
      { title: '會員卡', content: '多間餐廳會員卡添加，簡單方便一站式', img: util.getImgPath('Web_mobile02.jpg') }, { title: '會員福利', content: '消費儲分，兌換積分，會員優惠劵', img: util.getImgPath('Web_mobile03.jpg') },
      { title: '優惠劵', content: '迎新優惠，升級優惠，生日優惠。會員優惠停不了', img: util.getImgPath('Web_mobile04.jpg') }];
    this.introduce = introduce;
    // 組裝 餐廳案列
    let restaurantCase = [
      { title: '人手不足，成本每季增加', content: '客人利用QR Code點餐系統令餐廳節省接近8-12%待應人手，加上某些餐廳更大大節省了點餐紙的印刷成本。', img: util.getImgPath('accounting.png'), go: '/daxiwu' },
      { title: '想與顧客關係更貼近', content: 'APP會員系統，會員可以儲分換優惠，餐廳也可以推送優惠劵給顧客，與顧客更多互動及更貼近', img: util.getImgPath('member.png'), go: '/biefu' },
      { title: '溝通問題，經常落錯單', content: '顧客透過QR code自助點單，減少顧客與待應溝通出錯的機會，另外POS系統支援多國語言，減低廚房出錯問題', img: util.getImgPath('wrong_order.png'), go: '/huangzhengzheng' }];
    this.restaurantCase = restaurantCase;

  },
  created () { },
  methods: {
    initWindow () {
      let W = util.W(), w = W.w, h = W.h;
      this.window.bodyH = h;
      this.window.swiperH = parseInt(w / 2.71463);
      this.window.w = w;
      if (w < 700) {
        this.window.type = '600';
        this.window.introduce_class = 'introduce-app_2'
        this.window.restaurant_class = 'restaurant-case_2'
      } else if (w < 1000) {
        this.window.type = '900';
        this.window.introduce_class = "introduce-app_1"
        this.window.restaurant_class = 'restaurant-case_1'
      } else {
        this.window.type = '1000';
        this.window.introduce_class = '';
        this.window.restaurant_class = ''
      }
    },
  }
}
</script>
<style lang="less" scoped>
.home {
  background-color: rgb(249, 249, 249);
  .m-body {
    .swiper {
      background-color: #fff;
      margin-bottom: 50px;
      & /deep/ .el-carousel__container {
        height: calc(100% - 30px);
      }
    }
    .introduce-app_1 {
      flex-wrap: wrap;
      .item {
        width: calc(50% - 20px) !important;
      }
    }
    .introduce-app_2 {
      padding-top: 0 !important;
      .item {
        width: 100% !important;
      }
    }
    .introduce-app {
      display: flex;
      flex-wrap: wrap;
      padding: 50px 10%;
      .item {
        width: calc(25% - 20px);
        display: flex;
        flex-direction: column;
        align-items: center;
        border-top: 3px solid #fff;
        padding-top: 20px;
        margin: 0 10px;
        justify-content: space-between;
        img {
          width: 100%;
          background-color: #fff;
        }
      }
      & * {
        font-size: 100%;
      }
      & h3 {
        font-weight: 600;
        font-size: 25.5px;
        margin-bottom: 30px;
      }
      & h6 {
        margin-bottom: 30px;
        font-weight: 400;
        font-size: 22px;
        text-align: center;
      }
    }
  }
}
</style>